import React from 'react';
import styled, { css } from 'styled-components';
import { ImageWrapperProps } from './types';

const StyledImageWrapper = styled.div<ImageWrapperProps>`
  display: block;
  width: 12.5rem;
  margin: 1.5rem 0;
  flex-shrink: 0;
  border-radius: 20rem;
  overflow: hidden;

  ${(props) =>
    !props.transparentBackground &&
    css`
      .gatsby-image-wrapper {
        height: 12.5rem;
      }
    `}
`;

const ImageWrapper: React.FC<ImageWrapperProps> = ({ children, transparentBackground }) => (
  <StyledImageWrapper transparentBackground={transparentBackground}>{children}</StyledImageWrapper>
);

export default ImageWrapper;
