import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import getImageData from '$utils/getImageData';
import Paragraph from '$components/Paragraph';
import AuthorName from './AuthorName';
import AuthorTitle from './AuthorTitle';
import Content from './Content';
import ImageWrapper from './ImageWrapper';
import ContactImage from '$components/ContactImage';
import { WrapXs } from '$components/Wraps';
import { BlockQuoteProps, ContentProps } from './types';
import FadeInSection from '$components/FadeInSection';
import Markdown from '$components/Markdown';

const Outer = styled(WrapXs)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Inner = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 640px) {
    flex-direction: row;

    ${(props) =>
      props.rtl &&
      css`
        flex-direction: row-reverse;
      `}
  }
  blockquote {
    margin: 0;
  }
`;

const BlockQuote: React.FC<BlockQuoteProps> = ({
  imageHasTransparentBackground,
  imagePlacement,
  name,
  quote,
  title,
  image,
  showImage,
}) => {
  const transparentBackground = imageHasTransparentBackground;
  const rtl = imagePlacement === 'right';

  return (
    <Outer>
      <FadeInSection>
        <Inner rtl={rtl}>
          {showImage && (
            <ImageWrapper transparentBackground={transparentBackground}>
              {transparentBackground && <ContactImage image={image} />}
              {!transparentBackground && (
                <GatsbyImage image={getImageData(image.imageFile)} alt={image.alternativeText} />
              )}
            </ImageWrapper>
          )}
          <Content rtl={rtl} hasImage={showImage}>
            <blockquote>
              <Paragraph quote>
                <Markdown content={`“${quote}”`} />
              </Paragraph>
            </blockquote>
            <figcaption>
              <AuthorName>{name}</AuthorName>
              <AuthorTitle>{title}</AuthorTitle>
            </figcaption>
          </Content>
        </Inner>
      </FadeInSection>
    </Outer>
  );
};

export default BlockQuote;
