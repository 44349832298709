import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div`
  font-family: var(--font-sans);
  font-size: 0.75rem;
  color: var(--color-slate4);
  font-weight: 700;
  letter-spacing: 0.04em;
  margin: 0 0 0.5rem 0;
`;

const AuthorTitle: React.FC = ({ children }) => <StyledTitle>{children}</StyledTitle>;

export default AuthorTitle;
