import React from 'react';
import styled, { css } from 'styled-components';
import { ContentProps } from './types';

const StyledContent = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media screen and (min-width: 640px) {
    margin-right: 0;

    ${(props) =>
      props.hasImage &&
      !props.rtl &&
      css`
        text-align: left;
        margin-left: 3.75rem;
      `}

    ${(props) =>
      props.hasImage &&
      props.rtl &&
      css`
        margin-left: 0;
        margin-right: 3.75rem;
        text-align: right;
      `}
  }

  ${(props) =>
    !props.hasImage &&
    !props.rtl &&
    css`
      text-align: left;
      padding-left: 6vw;
      border-left: 3px solid var(--color-citrus4);

      @media screen and (min-width: 640px) {
        padding-left: 2rem;
      }
    `}

  ${(props) =>
    !props.hasImage &&
    props.rtl &&
    css`
      text-align: right;
      padding-right: 6vw;
      border-right: 3px solid var(--color-citrus4);

      @media screen and (min-width: 640px) {
        padding-right: 2rem;
      }
    `}
`;

const Content: React.FC<ContentProps> = ({ children, rtl, hasImage }) => (
  <StyledContent rtl={rtl} hasImage={hasImage}>
    {children}
  </StyledContent>
);

export default Content;
