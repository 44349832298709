import React from 'react';
import styled from 'styled-components';

const StyledName = styled.div`
  font-family: var(--font-osynlig);
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  color: var(--color-slate3);
  line-height: 2;
  margin: 0;
`;

const AuthorName: React.FC = ({ children }) => <StyledName>{children}</StyledName>;

export default AuthorName;
